import { TabsChangeEventDetail } from '@wppopen/components-library'
import { WppBreadcrumb, WppIconChevron, WppTab, WppTabs, WppTypography } from '@wppopen/components-library-react'
import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { PaginationParams } from 'api/common/types'
import { useGetAgenciesByPermissions } from 'api/queries/agencies/useGetAgencies'
import { useUseCases } from 'api/queries/use-cases/useUseCases'
import { useSettingsPermissions } from 'hooks/useSettingsPermissions'
import { useToast } from 'hooks/useToast'
import { AgencyDashboardView } from 'pages/agency-dashboard/AgencyDashboardView'

import { UseCasesDashboardView } from './useCases/useCasesDashboardView/UseCasesDashboardView'
import { AgencyFetchDataParams } from '@/types/agencies/agencies'

export enum SettingsTabs {
  AGENCIES = 'agencies',
  USE_CASES = 'use-cases',
}

interface TabSearchParams {
  [SettingsTabs.AGENCIES]: URLSearchParams | null
  [SettingsTabs.USE_CASES]: URLSearchParams | null
}

export const Settings = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const viewQuery = searchParams.get('view')
  const currentTab = viewQuery === SettingsTabs.USE_CASES ? SettingsTabs.USE_CASES : SettingsTabs.AGENCIES
  const [tabSearchParams, setTabSearchParams] = useState<TabSearchParams>({
    [SettingsTabs.AGENCIES]: null,
    [SettingsTabs.USE_CASES]: null,
  })

  const agencyParams: PaginationParams<AgencyFetchDataParams> | undefined = tabSearchParams[SettingsTabs.AGENCIES]
    ? Object.fromEntries(tabSearchParams[SettingsTabs.AGENCIES]?.entries())
    : undefined

  useEffect(() => {
    setTabSearchParams(prev => ({ ...prev, [currentTab]: searchParams }))
  }, [searchParams, currentTab])

  const toast = useToast()
  const {
    data: { data: dataAgencies, paginator: agenciesPaginator } = {
      data: [],
      paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 },
    },
    isLoading: loadingAgencies,
    isError: errorAgencies,
  } = useGetAgenciesByPermissions({
    params: agencyParams,
  })
  const {
    data: { data: dataUseCases, paginator: useCasesPaginator } = {
      data: [],
      paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 },
    },
    isLoading: loadingUseCases,
    isError: errorUseCases,
  } = useUseCases({
    params: {
      page: tabSearchParams[SettingsTabs.USE_CASES]?.get('page') ?? '1',
      ...(tabSearchParams[SettingsTabs.USE_CASES]?.get('itemsPerPage') && {
        itemsPerPage: tabSearchParams[SettingsTabs.USE_CASES]?.get('itemsPerPage'),
      }),
    },
  })

  const { totalItems: useCasesTotal } = useCasesPaginator
  const { totalItems: agenciesTotal } = agenciesPaginator

  const { isAgenciesAdmin } = useSettingsPermissions()

  if (errorAgencies) {
    toast.showToast({ message: 'Failed to fetch agencies', type: 'error' })
  }

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    const value = event.detail.value
    if (value === SettingsTabs.AGENCIES) {
      if (tabSearchParams[SettingsTabs.AGENCIES]) {
        setSearchParams(tabSearchParams[SettingsTabs.AGENCIES])
      } else {
        setSearchParams({ view: SettingsTabs.AGENCIES, mode: 'grid' })
      }
    } else if (value === SettingsTabs.USE_CASES) {
      if (tabSearchParams[SettingsTabs.USE_CASES]) {
        setSearchParams(tabSearchParams[SettingsTabs.USE_CASES])
      } else {
        setSearchParams({ view: SettingsTabs.USE_CASES })
      }
    }
  }
  return (
    <div className="pb-16">
      <div className="flex mb-4">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[{ label: 'Back to Project Dashboard', path: '/' }]}
        />
      </div>

      <div className="flex justify-start mb-4">
        <WppTypography type="xl-heading">Settings</WppTypography>
      </div>

      <WppTabs value={currentTab} onWppChange={handleTabChange}>
        {/* add small padding either side to account size of tab space when count is added */}
        <WppTab className={agenciesTotal === 0 ? 'px-1' : ''} value={SettingsTabs.AGENCIES}>
          Agencies ({agenciesTotal})
        </WppTab>
        {/* add small padding either side to account size of tab space when count is added */}
        <WppTab className={useCasesTotal === 0 ? 'px-1' : ''} value={SettingsTabs.USE_CASES}>
          Use Cases ({useCasesTotal})
        </WppTab>
      </WppTabs>
      {
        {
          [SettingsTabs.AGENCIES]: (
            <AgencyDashboardView
              paginator={agenciesPaginator}
              navigate={navigate}
              agencies={dataAgencies}
              isFetching={loadingAgencies && !errorAgencies}
              isAgenciesAdmin={isAgenciesAdmin}
            />
          ),
          [SettingsTabs.USE_CASES]: (
            <UseCasesDashboardView
              paginator={useCasesPaginator}
              navigate={navigate}
              useCases={dataUseCases}
              isFetching={loadingUseCases && !errorUseCases}
            />
          ),
        }[currentTab]
      }
    </div>
  )
}
