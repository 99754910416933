import { WppModal, WppSpinner } from '@wppopen/components-library-react'
import { useState, useEffect } from 'react'
import { NavigateFunction, useSearchParams } from 'react-router-dom'

import { Paginator } from 'api/common/types'
import { SettingsTabs } from 'pages/settings/Settings'
import { UseCase } from 'types/use-cases/useCase'

import { UseCaseDeleteModal } from '../UseCaseDeleteModal'
import { UseCasesCards } from '../useCasesCards/UseCasesCards'
import { UseCasesFilterGroup } from '../useCasesFilterGroup/UseCasesFilterGroup'

export interface Props {
  isFetching: boolean
  navigate: NavigateFunction
  useCases: UseCase[]
  paginator: Paginator
}

export const UseCasesDashboardView = ({ navigate, useCases, isFetching, paginator }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const handleEditClick = (id: string) => {
    navigate(`/rfi-helper-tool/settings/use-cases/${id}`)
  }

  useEffect(() => {
    if (!searchParams.get('view')) {
      setSearchParams(prev => {
        prev.set('view', SettingsTabs.USE_CASES)
        return prev
      })
    }
    if (!searchParams.get('page')) {
      setSearchParams(prev => {
        prev.set('page', '1')
        return prev
      })
    }
  }, [searchParams, setSearchParams])

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [id, setId] = useState('')
  const [name, setname] = useState('')

  const handleDeleteClick = async (id: string, name?: string) => {
    setId(id)
    if (name) {
      setname(name)
    }
    setIsDeleteModalOpen(true)
  }

  return (
    <div className="mt-7">
      <UseCasesFilterGroup disabled={isFetching} />

      {isFetching && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}
      {!isFetching && (
        <UseCasesCards
          paginator={paginator}
          useCases={useCases}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      )}

      <WppModal open={isDeleteModalOpen} onWppModalClose={() => setIsDeleteModalOpen(false)} size="s">
        <UseCaseDeleteModal
          useCaseId={id}
          name={name}
          handleModalClose={() => {
            setIsDeleteModalOpen(false)
          }}
          handleDeleteSuccess={() => {
            setIsDeleteModalOpen(false)
          }}
        />
      </WppModal>
    </div>
  )
}
