import { rfiHelperApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { UseCase } from 'types/use-cases/useCase'

export interface FetchUseCasesParams {
  rfiId?: string
  pitchTypeIds?: string[]
}

export const fetchUseCases = ({ rfiId, pitchTypeIds, page, itemsPerPage }: PaginationParams<FetchUseCasesParams>) =>
  rfiHelperApi.get<PaginatedResponse<UseCase>>('/use-cases', {
    params: {
      rfiId,
      pitchTypeIds: pitchTypeIds?.join(','),
      ...(page !== null && { page: page }),
      ...(itemsPerPage !== null && { itemsPerPage: itemsPerPage }),
    },
    paramsSerializer: { indexes: null },
  })
